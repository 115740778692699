import React from 'react';

import Video from 'components/Video';
import Grid from 'components/Grid';
import GridItem from 'components/GridItem';


export default function VideoNoText({ pageBuilder }) {
  const { videoUrl } = pageBuilder;

  if(videoUrl) return (
    <Grid>
      <GridItem style={{ position: 'relative' }}>
        <Video
          muted={false}
          controls
          url={videoUrl}
        />
      </GridItem>
    </Grid>
  )

  return null
}

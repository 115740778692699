import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import Wrap from "components/Wrap";
import Headline from "components/Headline";

export const WrapStyled = styled(Wrap)({
  padding: "70px 0 24px",
  position: "relative",
  zIndex: 1,
  // display: 'flex',
  // alignItems: 'center',
  // overflow: 'hidden',

  [media("tablet")]: {
    // minHeight: '100vh',
    padding: "70px 0",
  },
});

export const TextStyled = styled("div")({}, (props) => ({
  margin: props.margin,
  maxWidth: "none",

  [media("laptop")]: {
    maxWidth: props.maxWidth || "none",
  },

  p: {
    margin: 0,
  },
}));

export const HeadlineStyled = styled(Headline)({}, (props) => ({}));

import React from 'react';

import { parseAsText } from 'utils/parser';
import Redactor from 'components/Redactor';
import Video from 'components/Video';
import Grid from 'components/Grid';
import GridItem from 'components/GridItem';
import {
  TextStyled,
  HeadlineStyled
} from './VideoStyled';


export default function VideoTextLeft({ pageBuilder }) {
  const { videoUrl, videoHeadline, videoHeadlineTag, videoText } = pageBuilder;
  const textContent = videoText?.content;

  return (
    <Grid>
      <GridItem
        mobile={1}
        laptop={6 / 12}
        desktop={4 / 12}
      >
        <Grid
          style={{display: 'flex', 'align-items': 'center', 'flex-direction': 'row'}}
        >
            <GridItem>
              {videoHeadline && (
                <HeadlineStyled
                  margin="0 0 30px"
                  element={videoHeadlineTag}
                >
                  {parseAsText(videoHeadline)}
                </HeadlineStyled>
              )}
              {videoText && (
                <TextStyled
                  margin="0 0 30px"
                  maxWidth="800"
                >
                  <Redactor content={textContent} parseType="content" />
                </TextStyled>
              )}
            </GridItem>
        </Grid>
      </GridItem>
      <GridItem
        mobile={1}
        laptop={6 / 12}
        desktop={8 / 12}
        style={{ position: 'relative' }}
      >
        {videoUrl && (
          <Video
            muted={false}
            controls
            url={videoUrl}
          />
        )}
      </GridItem>
    </Grid>
  )
}

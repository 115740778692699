import React from 'react';
import { graphql } from 'gatsby';

import { parseAsText } from 'utils/parser';
import Redactor from 'components/Redactor';
import Video from 'components/Video';
import Grid from 'components/Grid';
import GridItem from 'components/GridItem';
import {
  WrapStyled,
  TextStyled,
  HeadlineStyled
} from './VideoStyled';


export default function VideoTextBelow({ pageBuilder }) {
  const { videoUrl, videoHeadline, videoHeadlineTag, videoText, videoTheme, videoLayout } = pageBuilder;
  const textContent = videoText?.content;

  return (
    <>
      <Grid>
        {videoHeadline && (
          <GridItem mobile={1} laptop={6 / 12} desktop={4 / 12} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <HeadlineStyled
              margin="0 0 30px"
              element={videoHeadlineTag}
            >
              {parseAsText(videoHeadline)}
            </HeadlineStyled>
          </GridItem>
        )}
        <GridItem mobile={1} laptop={6 / 12} desktop={8 / 12} style={{ position: 'relative' }}>
          {videoUrl && (
            <Video
              muted={false}
              controls
              url={videoUrl}
            />
          )}
        </GridItem>
      </Grid>
      {videoText && (
        <Grid>
          <GridItem>
            <TextStyled
              margin="0 0 30px"
              maxWidth="800"
            >
              <Redactor content={textContent} parseType="content" />
            </TextStyled>
          </GridItem>
        </Grid>
      )}
    </>
  )
}

import React from 'react';
import { graphql } from 'gatsby';

import Overlay from 'components/Overlay';
import SwitchTheme from 'components/SwitchTheme';

import VideoTextBelow from './VideoTextBelow';
import VideoNoText from './VideoNoText';
import VideoTextLeft from './VideoTextLeft';

import {
  WrapStyled,
} from './VideoStyled';

export default function Video({ pageBuilder }) {
  const { videoHeadline, videoText, videoTheme, videoLayout } = pageBuilder;

  const layout = {
    textLeft: VideoTextLeft,
    textBelow: VideoTextBelow,
    noText: VideoNoText,
  };

  const VideoComponent = (!videoHeadline && !videoText) ? layout.noText : layout[videoLayout];

  return (
    <SwitchTheme name={videoTheme}>
      <WrapStyled>
        <Overlay background="sectionImageTextBackground" />
        <VideoComponent pageBuilder={pageBuilder}  />
      </WrapStyled>
    </SwitchTheme>
  );
}

export const query = graphql`
  fragment PageBuilderVideoQuery on Craft_PageBuilderVideo {
    videoUrl
    videoHeadlineTag
    videoHeadline
    videoText {
      content
    }
    videoTheme
    videoLayout
  }
`;
